const cookieName = "aviator_auth_token";

(function() {
    var origOpen = XMLHttpRequest.prototype.open;
    XMLHttpRequest.prototype.open = function() {
        this.addEventListener('load', function() {
            const isUnauthorized = this.status === 401;
            if (isUnauthorized) {
              document.cookie = `${cookieName}= ;path=/; Max-Age=0; SameSite=Strict`;
              window.location.href = '/login';
            }
        });
        origOpen.apply(this, arguments);
    };
})();

const {Elm} = require('../../elm/Main.elm');

const isReportsThumbnailEnabledKey = "isReportsThumbnailEnabled";
const isMediaFilesShowClassifiedEnabledKey = "isMediaFilesShowClassifiedEnabled";
const isBlurringEnabledKey = "isBlurringEnabled";
const dateTypeKey = "dateType"
const investigationExportTypeKey = "investigationExportType"
const storageKey = "store";

const appVersion = document.head.querySelector('meta[name="app-version"]').getAttribute("content");

const app = Elm.Main.init({
    flags: {
       userSettings: JSON.parse(localStorage.getItem(storageKey)),
       appVersion: appVersion
    }
});

function updateStoreItem(key, value) {
    let store;
    if (localStorage.getItem(storageKey) === null) {
        store = {};
    } else {
        store = JSON.parse(localStorage.getItem(storageKey));
    }
    store[key] = value;
    localStorage.setItem(storageKey, JSON.stringify(store));
    app.ports.onStoreChange.send(store);
}

app.ports.storeMediaFilesIsShowClassifiedEnabled.subscribe((isShowClassifiedEnabled) =>
    updateStoreItem(isMediaFilesShowClassifiedEnabledKey, isShowClassifiedEnabled)
);

app.ports.storeBlurSetting.subscribe((isEnabled) =>
    updateStoreItem(isBlurringEnabledKey, isEnabled)
);

app.ports.storeReportsThumbnailEnabledKey.subscribe((isEnabled) =>
    updateStoreItem(isReportsThumbnailEnabledKey, isEnabled)
);

app.ports.startVideo.subscribe((elementId) => {
    const videoElement = document.getElementById(elementId);
    if (videoElement !== null) {
        videoElement.play();
    }
});
app.ports.stopVideo.subscribe((elementId) => {
    const videoElement = document.getElementById(elementId);
    if (videoElement !== null) {
        videoElement.pause();
    }
});
app.ports.storeDateType.subscribe((dateType) =>
    updateStoreItem(dateTypeKey, dateType)
);
app.ports.storeInvestigationExportType.subscribe((type_) =>
    updateStoreItem(investigationExportTypeKey, type_)
);
