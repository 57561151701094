port module Main exposing (main)

import About.Page as About
import Browser exposing (Document, UrlRequest)
import Browser.Navigation as Navigation
import Dashboard.Page
import Effect exposing (Effect)
import Html exposing (h1, p, text)
import Investigation.DetailPage exposing (Msg(..))
import Investigation.IndexPage
import Json.Decode as Decode exposing (Decoder, Error)
import Json.Decode.Pipeline as Decode
import Logging.Page
import Login
import Media.IndexPage
import Page
import Report.DetailPage
import Report.FailedReportIndexPage as FailedReportIndexPage
import Report.IndexPage
import Route exposing (newPaginationQuery)
import Settings.Page
import Settings.UserSettings as UserSettings exposing (UserSettings)
import System.Page
import Url exposing (Url)



-- MAIN


main : Program Decode.Value AppModel Msg
main =
    Browser.application
        { init = init
        , update = appUpdate
        , subscriptions = subscriptions
        , view = appView
        , onUrlRequest = LinkClicked
        , onUrlChange = UrlChanged
        }



-- MODEL


type AppModel
    = Succeeded Model
    | Failed Error


type alias Model =
    { page : Page
    , navigationKey : Navigation.Key
    , userSettings : UserSettings
    , appVersion : Maybe String
    }


type Page
    = NotFound
    | Login (Maybe Login.ErrorMessage)
    | About About.Model
    | Dashboard Dashboard.Page.Model
    | Reports Report.IndexPage.Model
    | Report Report.DetailPage.AppModel
    | FailedReports FailedReportIndexPage.AppModel
    | MediaFiles Media.IndexPage.AppModel
    | Investigations Investigation.IndexPage.Model
    | Investigation Investigation.DetailPage.Model
    | LogOverview Logging.Page.Model
    | Settings Settings.Page.Model
    | System


type alias Flags =
    { userSettings : UserSettings
    , appVersion : Maybe String
    }


flagsDecoder : Decoder Flags
flagsDecoder =
    Decode.succeed Flags
        |> Decode.required "userSettings" UserSettings.decoder
        |> Decode.optional "appVersion" (Decode.maybe Decode.string) Nothing


init : Decode.Value -> Url -> Navigation.Key -> ( AppModel, Cmd Msg )
init encodedFlags url navigationKey =
    case Decode.decodeValue flagsDecoder encodedFlags of
        Ok flags ->
            changeRouteTo
                flags.userSettings
                flags.appVersion
                url
                navigationKey
                |> Tuple.mapFirst Succeeded

        Err error ->
            ( Failed error, Cmd.none )


changeRouteTo : UserSettings -> Maybe String -> Url -> Navigation.Key -> ( Model, Cmd Msg )
changeRouteTo userSettings appVersion url navigationKey =
    let
        toCmd : Effect msg -> Cmd msg
        toCmd =
            toCmdWithNavKey navigationKey
    in
    case Route.fromUrl url of
        Nothing ->
            ( { page = NotFound
              , navigationKey = navigationKey
              , userSettings = userSettings
              , appVersion = appVersion
              }
            , Cmd.none
            )

        Just (Route.Login errorMessage) ->
            ( { page = Login errorMessage
              , navigationKey = navigationKey
              , userSettings = userSettings
              , appVersion = appVersion
              }
            , Cmd.none
            )

        Just Route.Logout ->
            ( { page = Login Nothing
              , navigationKey = navigationKey
              , userSettings = userSettings
              , appVersion = appVersion
              }
            , Cmd.none
            )

        Just Route.About ->
            About.init appVersion
                |> Tuple.mapSecond toCmd
                |> updateWith userSettings appVersion navigationKey About GotAboutMsg

        Just Route.Dashboard ->
            Dashboard.Page.init
                |> Tuple.mapSecond toCmd
                |> updateWith userSettings appVersion navigationKey Dashboard GotDashboardMsg

        Just (Route.Reports reportsQuery) ->
            Report.IndexPage.init reportsQuery
                (UserSettings.isBlurringEnabled userSettings)
                (UserSettings.dateType userSettings)
                (UserSettings.isReportsThumbnailEnabled userSettings)
                |> Tuple.mapSecond toCmd
                |> updateWith userSettings appVersion navigationKey Reports GotReportsMsg

        Just (Route.Report reportId pagination) ->
            Report.DetailPage.init pagination (UserSettings.isBlurringEnabled userSettings) (UserSettings.dateType userSettings) reportId
                |> Tuple.mapSecond toCmd
                |> updateWith userSettings appVersion navigationKey Report GotReportMsg

        Just (Route.FailedReports queryParameters) ->
            FailedReportIndexPage.init queryParameters
                |> Tuple.mapSecond (Effect.map FailedReportIndexPage.InitMsg)
                |> Tuple.mapSecond toCmd
                |> updateWith userSettings appVersion navigationKey FailedReports GotFailedReportMsg

        Just (Route.MediaFiles searchParameters) ->
            Media.IndexPage.init searchParameters
                (UserSettings.isMediaFilesShowClassifiedEnabled userSettings)
                (UserSettings.isBlurringEnabled userSettings)
                (UserSettings.dateType userSettings)
                |> Tuple.mapSecond toCmd
                |> updateWith userSettings appVersion navigationKey MediaFiles GotClassificationMsg

        Just (Route.Investigations paginationQuery) ->
            Investigation.IndexPage.init paginationQuery
                |> Tuple.mapSecond toCmd
                |> updateWith userSettings appVersion navigationKey Investigations GotInvestigationsMessage

        Just (Route.InvestigationDetail investigationId) ->
            Investigation.DetailPage.init
                (UserSettings.dateType userSettings)
                (UserSettings.investigationExportType userSettings)
                investigationId
                |> Tuple.mapSecond toCmd
                |> updateWith userSettings appVersion navigationKey Investigation GotInvestigationMessage

        Just (Route.LogOverview searchParameters) ->
            Logging.Page.init searchParameters
                |> Tuple.mapSecond toCmd
                |> updateWith userSettings appVersion navigationKey LogOverview GotLogOverviewMessage

        Just (Route.MediaPdfLocation _) ->
            ( { page = NotFound
              , navigationKey = navigationKey
              , userSettings = userSettings
              , appVersion = appVersion
              }
            , Cmd.none
            )

        Just Route.Settings ->
            Settings.Page.init userSettings
                |> Tuple.mapSecond toCmd
                |> updateWith userSettings appVersion navigationKey Settings GotSettingsMessage

        Just Route.System ->
            ( { page = System
              , navigationKey = navigationKey
              , userSettings = userSettings
              , appVersion = appVersion
              }
            , Cmd.none
            )



-- UPDATE


type Msg
    = LinkClicked UrlRequest
    | UrlChanged Url
    | LogoutClicked
    | GotAboutMsg About.Msg
    | GotReportsMsg Report.IndexPage.Msg
    | GotReportMsg Report.DetailPage.AppMsg
    | GotFailedReportMsg FailedReportIndexPage.AppMsg
    | GotDashboardMsg Dashboard.Page.Msg
    | GotClassificationMsg Media.IndexPage.AppMsg
    | GotInvestigationsMessage Investigation.IndexPage.Msg
    | GotInvestigationMessage Investigation.DetailPage.Msg
    | GotLogOverviewMessage Logging.Page.Msg
    | StoreChanged Decode.Value
    | GotSettingsMessage Settings.Page.Msg


toCmdWithNavKey : Navigation.Key -> Effect msg -> Cmd msg
toCmdWithNavKey key =
    let
        replaceUrl : String -> Cmd msg
        replaceUrl =
            Navigation.replaceUrl key

        pushUrl : String -> Cmd msg
        pushUrl =
            Navigation.pushUrl key
    in
    Effect.toCmd pushUrl replaceUrl


appUpdate : Msg -> AppModel -> ( AppModel, Cmd Msg )
appUpdate msg appModel =
    case appModel of
        Succeeded model ->
            update msg model
                |> Tuple.mapFirst Succeeded

        Failed _ ->
            ( appModel, Cmd.none )


update : Msg -> Model -> ( Model, Cmd Msg )
update msg model =
    let
        toCmd : Effect msg -> Cmd msg
        toCmd =
            toCmdWithNavKey model.navigationKey
    in
    case ( msg, model.page ) of
        ( StoreChanged value, _ ) ->
            ( { model
                | userSettings =
                    Decode.decodeValue UserSettings.decoder value
                        |> Result.toMaybe
                        |> Maybe.withDefault model.userSettings
              }
            , Cmd.none
            )

        ( LinkClicked urlRequest, _ ) ->
            case urlRequest of
                Browser.Internal url ->
                    ( model, Navigation.pushUrl model.navigationKey <| Url.toString url )

                Browser.External href ->
                    ( model, Navigation.pushUrl model.navigationKey href )

        ( UrlChanged url, _ ) ->
            changeRouteTo model.userSettings model.appVersion url model.navigationKey

        ( LogoutClicked, _ ) ->
            ( model, Effect.loadRoute Route.logout |> toCmd )

        ( GotAboutMsg subMsg, About subModel ) ->
            ( About.update subMsg subModel
            , Cmd.none
            )
                |> updateWith model.userSettings model.appVersion model.navigationKey About GotAboutMsg

        ( GotReportsMsg subMsg, Reports subModel ) ->
            Report.IndexPage.update subMsg subModel
                |> Tuple.mapSecond toCmd
                |> updateWith model.userSettings model.appVersion model.navigationKey Reports GotReportsMsg

        ( GotReportMsg subMsg, Report subModel ) ->
            Report.DetailPage.appUpdate subMsg subModel
                |> Tuple.mapSecond toCmd
                |> updateWith model.userSettings model.appVersion model.navigationKey Report GotReportMsg

        ( GotFailedReportMsg subMsg, FailedReports subModel ) ->
            FailedReportIndexPage.appUpdate subMsg subModel
                |> Tuple.mapSecond toCmd
                |> updateWith model.userSettings model.appVersion model.navigationKey FailedReports GotFailedReportMsg

        ( GotDashboardMsg subMsg, Dashboard subModel ) ->
            ( Dashboard.Page.update subMsg subModel, Cmd.none )
                |> updateWith model.userSettings model.appVersion model.navigationKey Dashboard GotDashboardMsg

        ( GotClassificationMsg subMsg, MediaFiles subModel ) ->
            Media.IndexPage.appUpdate subMsg subModel
                |> Tuple.mapSecond toCmd
                |> updateWith model.userSettings model.appVersion model.navigationKey MediaFiles GotClassificationMsg

        ( GotInvestigationsMessage subMsg, Investigations subModel ) ->
            Investigation.IndexPage.update subMsg subModel
                |> Tuple.mapSecond toCmd
                |> updateWith model.userSettings model.appVersion model.navigationKey Investigations GotInvestigationsMessage

        ( GotInvestigationMessage subMsg, Investigation subModel ) ->
            case subMsg of
                InvestigationDeleted (Ok _) ->
                    let
                        ( updatedSubModel, updatedEffect ) =
                            Investigation.IndexPage.update Investigation.IndexPage.InvestigationDeleted (Tuple.first (Investigation.IndexPage.init newPaginationQuery))
                    in
                    ( updatedSubModel, Effect.batch [ updatedEffect, Effect.replaceUrl (Route.Investigations newPaginationQuery) ] )
                        |> Tuple.mapSecond toCmd
                        |> updateWith model.userSettings model.appVersion model.navigationKey Investigations GotInvestigationsMessage

                _ ->
                    Investigation.DetailPage.update subMsg subModel
                        |> Tuple.mapSecond toCmd
                        |> updateWith model.userSettings model.appVersion model.navigationKey Investigation GotInvestigationMessage

        ( GotLogOverviewMessage subMsg, LogOverview subModel ) ->
            Logging.Page.update subMsg subModel
                |> Tuple.mapSecond toCmd
                |> updateWith model.userSettings model.appVersion model.navigationKey LogOverview GotLogOverviewMessage

        ( GotSettingsMessage subMsg, Settings subModel ) ->
            Settings.Page.update subMsg subModel
                |> Tuple.mapSecond toCmd
                |> updateWith model.userSettings model.appVersion model.navigationKey Settings GotSettingsMessage

        _ ->
            ( model, Cmd.none )


updateWith :
    UserSettings
    -> Maybe String
    -> Navigation.Key
    -> (subModel -> Page)
    -> (subMsg -> Msg)
    -> ( subModel, Cmd subMsg )
    -> ( Model, Cmd Msg )
updateWith userSettings appVersion navigationKey toModel toMsg ( subModel, subCmd ) =
    ( { page = toModel subModel
      , navigationKey = navigationKey
      , userSettings = userSettings
      , appVersion = appVersion
      }
    , Cmd.map toMsg subCmd
    )



-- VIEW


appView : AppModel -> Document Msg
appView appModel =
    case appModel of
        Succeeded model ->
            view model

        Failed error ->
            pageView never
                Page.Other
                { title = "Oops, something went wrong"
                , children =
                    [ h1 [] [ text "Oops, something went wrong" ]
                    , p [] [ text <| Decode.errorToString error ]
                    ]
                }


pageView : (a -> Msg) -> Page.Page -> Page.Details a -> Document Msg
pageView =
    Page.view LogoutClicked


view : Model -> Document Msg
view model =
    case model.page of
        NotFound ->
            pageView never
                Page.Other
                { title = "404 not found"
                , children = [ text "no page found for url" ]
                }

        Login errorMessage ->
            { title = "Login", body = Login.view errorMessage }

        Dashboard subModel ->
            pageView never Page.Dashboard <| Dashboard.Page.view subModel

        Reports subModel ->
            pageView GotReportsMsg Page.Reports <| Report.IndexPage.view subModel

        Report subModel ->
            pageView GotReportMsg Page.Reports <| Report.DetailPage.appView subModel

        FailedReports subModel ->
            pageView GotFailedReportMsg Page.FailedReports <| FailedReportIndexPage.appView subModel

        MediaFiles subModel ->
            pageView GotClassificationMsg Page.MediaFiles <| Media.IndexPage.appView subModel

        Investigations subModel ->
            pageView GotInvestigationsMessage Page.Investigations <| Investigation.IndexPage.view subModel

        Investigation subModel ->
            pageView GotInvestigationMessage Page.Investigations <| Investigation.DetailPage.view subModel

        LogOverview subModel ->
            pageView GotLogOverviewMessage Page.LogOverview <| Logging.Page.view subModel

        About subModel ->
            pageView never Page.About (About.view subModel)

        Settings subModel ->
            pageView GotSettingsMessage Page.Settings <|
                { title = "Settings"
                , children = Settings.Page.view subModel
                }

        System ->
            pageView never Page.System <|
                { title = "System"
                , children = System.Page.view
                }


port onStoreChange : (Decode.Value -> msg) -> Sub msg



-- SUBSCRIPTIONS


subscriptions : AppModel -> Sub Msg
subscriptions appModel =
    case appModel of
        Succeeded model ->
            Sub.batch
                [ onStoreChange StoreChanged
                , case model.page of
                    About _ ->
                        Sub.none

                    Login _ ->
                        Sub.none

                    NotFound ->
                        Sub.none

                    Dashboard _ ->
                        Sub.none

                    Reports _ ->
                        Sub.map GotReportsMsg <| Report.IndexPage.subscriptions

                    Report _ ->
                        Sub.map GotReportMsg <| Report.DetailPage.subscriptions

                    FailedReports _ ->
                        Sub.map GotFailedReportMsg <| FailedReportIndexPage.subscriptions

                    MediaFiles _ ->
                        Sub.map GotClassificationMsg <| Media.IndexPage.subscriptions

                    Investigations _ ->
                        Sub.map GotInvestigationsMessage <| Investigation.IndexPage.subscriptions

                    Investigation _ ->
                        Sub.map GotInvestigationMessage <| Investigation.DetailPage.subscriptions

                    LogOverview _ ->
                        Sub.map GotLogOverviewMessage <| Logging.Page.subscriptions

                    Settings _ ->
                        Sub.none

                    System ->
                        Sub.none
                ]

        Failed _ ->
            Sub.none
